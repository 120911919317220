<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.mensagem_orcamento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.mensagem_orcamento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Mensagem', 'Inserir')"
      :sem-filtro="!buscarPermissao('Mensagem', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <div class="d-flex align-center">
      <botao-padrao
        v-if="podeExcluir && podeOrdenar"
        color="secondary"
        outlined
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('cima')"
      >
        <v-icon> $arrowUp </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="podeExcluir && podeOrdenar"
        color="secondary"
        outlined
        class="mx-2"
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('baixo')"
      >
        <v-icon> $arrowDown </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="
          podeExcluir && podeOrdenar && buscarPermissao('Mensagem', 'Ordernar')
        "
        color="secondary"
        outlined
        @click="salvarOrdenacao"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar_ordenacao') }}
      </botao-padrao>

      <botao-padrao
        v-if="podeExcluir && buscarPermissao('Mensagem', 'Deletar')"
        class="my-2 ml-2"
        outlined
        color="danger"
        @click="excluirRegistros"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('Checklist', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="v => filtroAplicado.filter = v"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              v-if="buscarPermissao('Editar')"
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>

<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import MensagemOrcamentoService from '@common/services/cadastros/MensagemOrcamentoService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import Filtro from './filtro.vue';
import helpers from '@/common/utils/helpers';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'sequencia',
            text: this.$t('modulos.mensagem_orcamento.tabela.sequencia'),
            sortable: true,
          },
          {
            value: 'titulo',
            text: this.$t('modulos.mensagem_orcamento.tabela.titulo'),
            sortable: true,
          },
          {
            value: 'classificacao',
            text: this.$t('modulos.mensagem_orcamento.tabela.classificacao'),
            sortable: true,
          },
          {
            value: 'flagObrigatorio',
            text: this.$t('modulos.mensagem_orcamento.tabela.obrigatorio'),
            sortable: true,
            formatter: (v) =>
              helpers.BoleanoEnum.find((el) => el.value == v).text,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumClassificacao: [],
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
    podeOrdenar: function () {
      if (Object.keys(this.filtroAplicado).length > 0) return false;
      return true;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.mensagem_orcamento.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Mensagem', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: async function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.paginaAtual = paginaAtual;
      this.tabela.porPagina = porPagina;
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      await this.buscarClassificacao();
      this.tabela.dados = [];
      MensagemOrcamentoService.listar(parametros)
        .then((res) => {
          const arrayOrdenacao = [];
          res.data.items.forEach((item) => {
            item.classificacao = this.enumClassificacao.find(
              (el) => el.value === item.descricaoClassificacao
            ).text;
            arrayOrdenacao[item.sequencia] = item;
          });
          this.tabela.dados = arrayOrdenacao;
          this.limparVaziosTabela();
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    limparVaziosTabela: function () {
      this.tabela.dados = this.tabela.dados.filter((el) => el);
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        this.tabela.selecionados.forEach((item) => {
          MensagemOrcamentoService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(`modulos.mensagem_orcamento.exclusao_sucesso`)
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.limparVaziosTabela();
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'mensagem-orcamento-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'mensagem-orcamento-editar',
        params: { id: item.id },
      });
    },
    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
      });
    },
    salvarOrdenacao: function () {
      this.limparVaziosTabela();
      this.$store.dispatch('Layout/iniciarCarregamento');
      MensagemOrcamentoService.salvarOrdenacao({
        ids: this.tabela.dados.map((m) => m.id),
      })
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.mensagem_orcamento.ordenacao_sucesso`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tabela.selecionados = [];
        });
    },
    buscarClassificacao: async function () {
      await EnumeradorService.buscar('EnumMensagemClassificacao').then(
        (res) => {
          this.enumClassificacao = res;
        }
      );
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
