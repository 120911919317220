<template>
  <div class="row">
    <input-text
      v-model="filtro.titulo"
      class="col-6"
      :label="$t('modulos.mensagem_orcamento.filtro.titulo')"
      :placeholder="$t('modulos.mensagem_orcamento.filtro.titulo')"
      em-filtro
    />
    <input-select
      v-model="filtro.classificacao"
      :options="opcoes.classificacao"
      class="col-6"
      :label="$t('modulos.mensagem_orcamento.formulario.classificacao')"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        classificacao : []
      },
    };
  },
  mounted() {
    this.buscarClassificacao();
  },
  methods: {
    buscarClassificacao : function(){
      EnumeradorService.buscar('EnumMensagemClassificacao').then((res) => {
        this.opcoes.classificacao = res
      });
    }
  }
};
</script>

